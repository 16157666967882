@use "sass:math";

// Asset URL Prefix
$asset-url-prefix: "/";

// Page Width
$page-max-width: 1100px;
$page-extra-width: 200px;

// Breakpoints & Media Queries
// ---> important: any changes to these values must also be made in global.js!
$breakpoints: (
  xs: 1px,
  s: 420px,
  m: 760px,
  l: $page-max-width,
  xl: 1400px
);
$mediaqueries: (
  xs: '(min-width: #{em(map-get($breakpoints, xs))})',
  s: '(min-width: #{em(map-get($breakpoints, s))})',
  m: '(min-width: #{em(map-get($breakpoints, m))})',
  l: '(min-width: #{em(map-get($breakpoints, l))})',
  xl: '(min-width: #{em(map-get($breakpoints, xl))})'
);

// Gutters
$gutters: (
  xs: 20px,
  s: 30px,
  m: 40px,
  l: 50px,
  xl: 70px
);

// Base Colors
$colors: (
  gray: (white: #fff,
    light: #D1DEEA,
    base: #A0AEBA,
    dark: #88A2BA,
    x-dark: #333),
  yellow: (base: #FFC600,
    dark: #F8AD38,
    hover: #FFD94E,
    active: #F1B900,
    text: #3B2300),
  blue: (10: var(--background-secondary),
    09: var(--background-primary),
    08: var(--background-secondary-alt),
    06: var(--background-tertiary-alt),
    05: var(--background-tertiary),
    02: #00CAF4,
    button: #53DAFF,
    hover: #9ce7ff,
    active: #00CBFC),
  status: (error: #FF4B00,
    success: #7ED321)
);

// Font Colors
$base-font-color: palette(gray, white);
$action-color: palette(yellow, base);

// Border
$base-border-color: palette(gray, light);
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: palette(blue, 09);
$secondary-background-color: tint($base-border-color, 75%);
$input-background-color: #FFF;

// Typography
$fontsizes: (
  base: 16px,
  xs: 9px,
  s: 18px,
  m: 20px,
  l: 24px,
  xl: 36px,
  xxl: 48px
);
$base-font-size: fontsize(base);
$fontweights: (
  light: 300,
  regular: 400,
  medium: 500,
  bold: 600,
  x-bold: 700
);

// Line height
$base-line-height: rem(30px);
$heading-line-height: 1.3;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: rem(24px);
$small-spacing: math.div($base-spacing, 2);
$base-z-index: 0;

// Focus
$focus-outline-color: transparent;
$focus-outline-width: 0px;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: 0px;

// Animations
$base-duration: 250ms;
$base-timing: ease;
